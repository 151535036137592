import Link from "next/link";
import BestCarRentalAirport from "./BestCarRentalAirport";
// import OtherCarRentalCountry from "./OtherCarRentalCountry";
// import PopularCarRentalCountry from "./PopularCarRentalCountry";

const BestCarRental = ({
  locations,
  type,
}: {
  locations: any;
  type?: string;
}) => {
  return (
    <section
      id="best-car-rental"
      className="bg-white lg:px-[0.813rem] lg:py-[3.75rem]"
    >
      <div className="grid gap-4 mx-auto max-w-7xl">
        {type !== "mid" && (
          <div className="flex justify-between w-full">
            <div className="space-y-[6px] lg:space-y-[10px] mb-[18px] m-4 lg:m-0">
              <h2 className="pb-4 text-base font-extrabold lg:text-3xl text-my-dark-blue">
                Find Your Car Rental Anywhere in the World
              </h2>
              <p className="space-y-6 text-sm font-normal text-my-light-blue lg:font-light lg:text-base">
                You can find a rental car from the following locations listed
                below. Need help finding your locations? Here&apos;s the full
                list of{" "}
                <Link
                  href="/airports-in-united-states"
                  className="underline hover:opacity-80"
                >
                  Car rentals locations in the United States Airports.
                </Link>
              </p>
            </div>
          </div>
        )}
        <BestCarRentalAirport data={locations} type={type} />
        {/* <PopularCarRentalCountry data={locations} /> */}
        {/* <OtherCarRentalCountry data={locations} /> */}
      </div>
    </section>
  );
};

export default BestCarRental;
